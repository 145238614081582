<template>
  <div id="app">
    <transition name="fade" mode="out-in">
    <keep-alive>
    <router-view/>
    </keep-alive>
     </transition>
     
     <v-ons-toast :visible.sync="updateExists" animation="ascend">
       An update is avaiable
      <button @click="refreshApp">Update</button>
    </v-ons-toast>
  </div>

</template>
<script>
import update from './update'

export default {
  mixins: [update],

}
</script>
<style >
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
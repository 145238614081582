var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-ons-page',{attrs:{"id":"taggedpostsPage"}},[_c('div',[(_vm.filteredPosts.length)?_c('div',[_c('div',{staticClass:"content",staticStyle:{"height":"85%"}},_vm._l((_vm.filteredPosts),function(post){return _c('div',{key:post.id,staticClass:"post"},[_c('v-ons-card',{attrs:{"id":post.id}},[_c('v-ons-list-item',{staticClass:"postlistitem"},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"list-item__thumbnail",attrs:{"src":post.userPhotoURL
                      ? post.userPhotoURL
                      : 'https://ui-avatars.com/api/?rounded=true&name=' +
                        post.userName}})]),_c('div',{staticClass:"center"},[_c('span',{staticClass:"list-item__title",staticStyle:{"color":"blue"},on:{"click":function($event){return _vm.oneClick(post.id)}}},[_vm._v(_vm._s(post.userName))]),_c('span',{staticClass:"list-item__subtitle",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(post.title)+" · "+_vm._s(_vm._f("formatDate")(post.createdOn))),_c('br')]),_c('span',{staticClass:"list-item__subtitle",staticStyle:{"font-weight":"bold","font-style":"italic"}},[_vm._v(" "+_vm._s(post.selectedAddress))])]),_c('div',{staticClass:"right"},[_c('v-ons-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentUser.uid == post.userId),expression:"currentUser.uid == post.userId"}],staticClass:"list-item__icon",staticStyle:{"color":"red"},attrs:{"icon":"md-delete"},on:{"click":function($event){return _vm.deletePost(post.id)}}})],1)]),_c('div',{staticClass:"content",attrs:{"data-raw":post.content},on:{"click":_vm.expandPost}},[_vm._v(" "+_vm._s(_vm._f("trimLength")(post.content))+" ")]),_c('div',{staticStyle:{"margin-bottom":"5px"}}),_c('div',[_c('button',{staticClass:"toolbar-button",attrs:{"id":"btnLikes2"},on:{"click":function($event){return _vm.toggleLikeUnLike(post.id, post.likes)}}},[_c('i',{class:[
                    _vm.likedPosts.indexOf(post.id) != -1 ? 'fas' : 'far',
                    'fa-heart' ],staticStyle:{"color":"orange","font-size":"20px","padding-left":"0px"}})]),_c('button',{staticClass:"toolbar-button",attrs:{"id":"btnComment2"},on:{"click":function($event){return _vm.viewpost(post)}}},[_c('i',{staticClass:"far fa-comment-alt",staticStyle:{"font-size":"20px"}}),_vm._v(" "+_vm._s(post.comments)+" ")]),_c('button',{staticClass:"toolbar-button",attrs:{"id":"btnCopy"},on:{"click":function($event){return _vm.copy(post.content)}}},[_c('i',{staticClass:"fa fa-copy",staticStyle:{"font-size":"20px","padding-left":"0px"}})]),_c('button',{staticClass:"toolbar-button",staticStyle:{"float":"right"},attrs:{"id":"btnLikes2"},on:{"click":function($event){return _vm.getLikedUserList(post.id)}}},[_c('i',{staticClass:"fas fa-heart",staticStyle:{"font-size":"20px","padding-left":"0px"}}),_vm._v(" "+_vm._s(post.likes)+" ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(post.centre),expression:"post.centre"}],staticClass:"toolbar-button",staticStyle:{"float":"right"},attrs:{"id":"btnMap"},on:{"click":function($event){return _vm.go2Map(post)}}},[_c('i',{staticClass:"fas fa-map-marked",staticStyle:{"font-size":"20px","padding-left":"0px"}})])])],1)],1)}),0)]):_c('div',[_c('v-ons-card',[_c('div',{staticClass:"content"},[_c('p',{staticClass:"no-results",staticStyle:{"text-align":"center","font-size":"20px","margin-top":"20px"}},[_vm._v(" No posts yet. ")])])])],1)]),_c('v-ons-dialog',{attrs:{"cancelable":"","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('p',[_c('v-ons-card',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Persons like the post")]),_vm._l((_vm.likeduserlist),function(item,index){return _c('v-ons-list',{key:index},[_c('v-ons-list-item',{staticClass:"postlistitem"},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"list-item__thumbnail",attrs:{"src":item.photoURL
                    ? item.photoURL
                    : 'https://ui-avatars.com/api/?rounded=true&name=' +
                      item.username}})]),_c('div',{staticClass:"center"},[_c('span',{staticClass:"list-item__title"},[_vm._v(_vm._s(item.username))])])])],1)})],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template id="ownerpropinfo">
  <v-ons-page id="ownerpropinfoPage">
    <v-ons-card >
      <div v-if="hasOwnerData">
        <div class="content">
          <div>
            <span>{{
              Owner_FullName1
            }}</span
            ><span style="float: right"
              ><v-ons-fab
                    :disabled="!userProfile.admin"
                    @click="goToOwnerEdit"
                    modifier="mini"
                ><v-ons-icon icon="md-edit" ></v-ons-icon></v-ons-fab
            ></span>
          </div>
          <div v-if="Owner_FullName2 != ''">
            <span >{{
              Owner_FullName2
            }}</span>
          </div>
          <div v-if="Owner_Company != ''">
            <span >{{ Owner_Company }}</span>
          </div>
          <div v-if="Owner_Address != ''">
            <p>{{ Owner_CivicAddress }}</p>
            <p>{{ Owner_AddressLine2 }}</p>
          </div>
          <v-ons-list>
            <v-ons-list-item>
              <div class="content">
                <div v-if="Owner_OfficeTel != ''">
                  <v-ons-icon icon="md-phone" style="color: blue;"></v-ons-icon>
                  Office: {{ Owner_OfficeTel }}
                </div>
                <div v-if="Owner_MobileTel != ''">
                  <i class="zmdi zmdi-phone" style="color: blue;"></i>
                  Mobile: {{ Owner_MobileTel }}
                </div>
                <div v-if="Owner_HomeTel != ''">
                  <i class="zmdi zmdi-phone" style="color: blue;"></i>
                  Home: {{ Owner_HomeTel }}
                </div>
                <div v-if="Owner_Email1 != ''">
                  <i class="zmdi zmdi-email" style="color: blue;"></i>
                  Email: {{ Owner_Email1 }}
                </div>
                <div v-if="Owner_Email2 != ''">
                  <i class="zmdi zmdi-email" style="color: blue;"></i>
                  Email: {{ Owner_Email2 }}
                </div>
              </div>
            </v-ons-list-item>
          </v-ons-list>
          <v-ons-list>
            <v-ons-list-item
              >Summary
              <v-ons-row style="height:100px;" vertical-align="center">
                <v-ons-col class="info-col" vertical-align="center" width="50%">
                  <div>
                    <img
                      src="../assets/img/property1.png"
                      alt="No Of Properties"
                      style="width:30px;height:30px;"
                    />
                  </div>
                  <div class="info-num">{{ Owner_NoOfProp }}</div>
                  <div class="info-unit">Properties</div>
                </v-ons-col>
                <v-ons-col class="info-col" vertical-align="center" width="50%">
                  <div>
                    <img
                      src="../assets/img/portfolio1.png"
                      alt="Total Portfolio"
                      style="width:30px;height:30px;"
                    />
                  </div>
                  <div class="info-num currency">
                    {{ Owner_TotalPortfolio }}
                  </div>
                  <div class="info-unit">Portfolio Value</div>
                </v-ons-col>
              </v-ons-row>
            </v-ons-list-item>
          </v-ons-list>
          <p style="font-size:xx-small">{{ owner.db }}</p>
        </div>
      </div>
    </v-ons-card>
    <v-ons-card >
      <div v-if="hasPropData">
        <div class="content">
          <div class="left">
            <span>
              <img
                :src="AddressURL"
                alt="Street View is not available."
                style="display:block;width:100%"
              />
            </span>
            <v-ons-row>
              <v-ons-col>
                {{ Prop_Address }}
              </v-ons-col>
            </v-ons-row>
            <v-ons-col> Zoning:</v-ons-col
            ><v-ons-col>{{ Prop_Zoning }} </v-ons-col>
            <v-ons-col> Legal Owner:</v-ons-col
            ><v-ons-col>{{ Prop_LegalOwner }} </v-ons-col>
          </div>
          <div style="left">
            <i
              class="fas fa-street-view"
              @click="showStreetView = true"
              style="display:none"
            ></i>
          </div>
          <div>
            <v-ons-row>
              <v-ons-col>Lot Size: </v-ons-col>
              <v-ons-col>{{ Prop_LotSize }}</v-ons-col>
            </v-ons-row>
            <div v-show="Prop_BuildingStoreys != ''">
              <v-ons-row>
                <v-ons-col>Building Storeys: </v-ons-col>
                <v-ons-col>{{ Prop_BuildingStoreys }}</v-ons-col>
              </v-ons-row>
            </div>
            <div v-show="Prop_NoOfUnits != 0">
              <v-ons-row>
                <v-ons-col>No Of Units: </v-ons-col>
                <v-ons-col>{{ Prop_NoOfUnits }}</v-ons-col>
              </v-ons-row>
            </div>
            <v-ons-row>
              <v-ons-col>Gross Tax: </v-ons-col>
              <v-ons-col>{{ Prop_GrossTax }}</v-ons-col>
            </v-ons-row>
            <v-ons-row>
              <v-ons-col>Assessment (year): </v-ons-col>
              <v-ons-col
                >{{ Prop_AssessmentTotalValue }} ({{
                  Prop_AssessmentYear
                }})</v-ons-col
              >
            </v-ons-row>
            <v-ons-row>
              <v-ons-col>Land:</v-ons-col>
              <v-ons-col>{{ Prop_AssessmentLand }}</v-ons-col>
            </v-ons-row>
            <v-ons-row>
              <v-ons-col>Buildings:</v-ons-col>
              <v-ons-col>{{ Prop_AssessmentBuilding }}</v-ons-col>
            </v-ons-row>
          </div>
        </div>
      </div>
    </v-ons-card>
    <v-ons-card >
      <div v-if="hasPropData">
        <v-ons-list-item @click="showTitleSearch">
          <v-ons-icon
            icon="md-file-text"
            size="30px"
            style="margin-right: 10px;"
          ></v-ons-icon>
          Title search
        </v-ons-list-item>
        <v-ons-list-item @click="showCompanySearch">
          <v-ons-icon
            icon="md-file-text"
            size="30px"
            style="margin-right: 10px;"
          ></v-ons-icon>
          Company search
        </v-ons-list-item>
      </div>
    </v-ons-card>
    <div style="position: relative; height: 450px;">
      <tagged-posts v-bind:hash="hash"></tagged-posts>
    </div>

    <v-ons-dialog cancelable :visible.sync="showDialog">
      <PDFJSViewer :path="`${path}`" :name="`${name}`" />
    </v-ons-dialog>
    <v-ons-fab
      v-show="false"
      position="bottom right"
      :disabled="!userProfile.admin"
      
      @click="goToOwnerEdit"
    >
      <v-ons-icon icon="ion-ios-create"></v-ons-icon>
    </v-ons-fab>
  </v-ons-page>
</template>
<script>
// Import the EventBus we just created.
import EventBus from "../config/event-bus";
import router from "../router";
//import BasicTableApp from "../components/portfoliotv";
import PDFJSViewer from "../components/PDFJSViewer";
import taggedPosts from "../components/taggedposts.vue";
import state from "../state";
import fb from "../firebase";

//for API call
import axios from "axios";
import { mapState } from "vuex";

const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)
export default {
  data: function() {
    return {
      Owner_FullName1: "",
      Owner_FullName2: "",
      Owner_Company: "",
      Owner_CivicAddress: "",
      Owner_AddressLine2: "",
      Owner_City: "",
      Owner_PostalCode: "",
      Owner_Province: "",
      Owner_Country: "",
      Owner_Address: "",
      Owner_HomeTel: "",
      Owner_OfficeTel: "",
      Owner_MobileTel: "",
      Owner_Email1: "",
      Owner_Email2: "",
      Owner_NoOfProp: 0,
      Owner_TotalPortfolio: 0,
      Owner_LastResult: "",
      Owner_NextAction: "",
      Owner_ContactID: "",
      Owner_Relationship: "",
      Owner_InvestmentRange: "",
      Owner_MarketPosition: "",
      Owner_Type: "",
      hasOwnerData: false,
      ReadOnlyDB: false,
      actionSheetVisible: false,
      router: router,
      owner: null,
      coordinate: null,

      Prop_PID: "",
      Prop_Address: "",
      Prop_Zoning: "",
      Prop_LotSize: 0,
      Prop_BuildingStoreys: 0,
      Prop_NoOfUnits: 0,
      Prop_GrossTax: 0,
      Prop_AssessmentTotalValue: 0,
      Prop_AssessmentYear: "",
      Prop_AssessmentLand: 0,
      Prop_AssessmentBuilding: 0,
      Prop_LegalOwner: "",
      hasPropData: false,
      hasNotes: false,
      hasPropHistory: false,
      hasPosts: true,
      AddressURL: "",
      dialogVisible: false,
      //actionSheetVisible: false,
      showStreetView: false,
      showDialog: false,
      lonlat: null,
      hash: null,
      hasTitleSearch: false,
      hasCompanySearch: false,
      // for using pdf.js pre-build web viewer
      name: "Test_PDF.pdf", //change which pdf file loads
      path: "lib/web/viewer.html", //path of the PDF.js viewer.html
    };
  },
  activated() {
    EventBus.$on("UpdateMap", (data) => {
      console.log("updatemap");
      this.goToMap();
    });
  },

  computed: {
    hasPropInfo() {
      return this.hasPropData;
    },

    hasOwnerInfo() {
      //return (this.hasData)
      return true;
    },
    ...mapState(["userProfile"]),
  },
  mounted() {
    EventBus.$on("get_parcelcoordinate", (data) => {
      console.log("Get coordinate event in ownerinfo: ", data);
      this.coordinate = data;
    }),
      EventBus.$on("get_db-contactid", (data) => {
        console.log("Get db contactid event in ownerinfo: ", data);
        this.getPortfolioRows(data);
      }),
      EventBus.$on("get_owner", (data) => {
        console.log("Get owner event in ownerinfo: ");

        if (data !== null) {
          this.owner = data;
          this.Owner_FullName1 = (
            data.FirstName1 +
            " " +
            data.LastName1
          ).trim();
          this.Owner_FullName2 = (
            data.FirstName2 +
            " " +
            data.LastName2
          ).trim();
          this.Owner_Company = data.Company.trim();

          this.Owner_Address = data.Address.trim();
          let Owner_AddressArray = data.Address.split(" ");
          let Owner_AddressArrayLength = Owner_AddressArray.length;
          this.Owner_Country = Owner_AddressArray[Owner_AddressArrayLength - 1];
          this.Owner_PostalCode =
            Owner_AddressArray[Owner_AddressArrayLength - 3] +
            " " +
            Owner_AddressArray[Owner_AddressArrayLength - 2];
          this.Owner_Province =
            Owner_AddressArray[Owner_AddressArrayLength - 4];

          if (
            Owner_AddressArray.indexOf("Westminster") > 0 &&
            Owner_AddressArray.indexOf("New") > 0
          ) {
            this.Owner_City = Owner_AddressArray.slice(
              Owner_AddressArrayLength - 6,
              Owner_AddressArrayLength - 4
            ).join(" ");
            this.Owner_AddressLine2 = Owner_AddressArray.slice(
              Owner_AddressArrayLength - 6,
              Owner_AddressArrayLength
            ).join(" ");
            this.Owner_CivicAddress = Owner_AddressArray.slice(
              0,
              Owner_AddressArrayLength - 6
            ).join(" ");
          } else {
            this.Owner_City = Owner_AddressArray[Owner_AddressArrayLength - 5];
            this.Owner_AddressLine2 = Owner_AddressArray.slice(
              Owner_AddressArrayLength - 5,
              Owner_AddressArrayLength
            ).join(" ");
            this.Owner_CivicAddress = Owner_AddressArray.slice(
              0,
              Owner_AddressArrayLength - 5
            ).join(" ");
          }

          this.Owner_HomeTel = data.HomeTel.trim();
          this.Owner_OfficeTel = data.ContactNo.trim();
          this.Owner_MobileTel = data.MobileTel.trim();
          this.Owner_Email1 = data.Email_key3.trim();
          this.Owner_Email2 = data.Email.trim();
          this.Owner_NoOfProp = data.NoOfProperties.trim();
          this.Owner_TotalPortfolio = data.TotalPortfolio.trim();
          this.Owner_LastResult = data.LastResult.trim();
          this.Owner_NextAction = data.NextAction.trim();
          this.Owner_ContactID = data.ContactID.trim();
          this.Owner_Relationship = data.Relationship.trim();
          this.Owner_InvestmentRange = data.InvestmentRange.trim();
          this.Owner_MarketPosition = data.MarketPosition.trim();
          this.Owner_Type = data.Type.trim();
          this.hasOwnerData = true;
          if (this.owner.db == "bc") {
            this.ReadOnlyDB = true;
          } else {
            this.ReadOnlyDB = false;
          }
        } else this.hasOwnerData = false;
      });
    EventBus.$on("get_hash", (data) => {
      console.log("Get hash event in propertyinfo");
      this.hash = data;
      console.log("hash in propertyinfo:", this.hash);
    }),
      EventBus.$on("get_IQAddress", (data) => {
        console.log("Get IQAddress event in propertyinfo", data);
        this.Address = data;
        this.PID = "";
      }),
      EventBus.$on("get_property", (data) => {
        console.log("Get property event in propertyinfo: ", data);
        if (data !== null) {
          this.Prop_PID = data.Pid;
          this.Prop_Address = data.CivicAddress;
          this.Prop_Zoning = data.Zoning;
          this.Prop_LotSize =
            (data.LotSize.split(" ")[0] / 43560).toFixed(2) + " acres";
          this.Prop_BuildingStoreys = data.BuildingStoreys.trim();
          this.Prop_NoOfUnits = data.NoOfUnit.trim();
          this.Prop_GrossTax = data.GrossTax.replace(/.(?=(?:.{3})+$)/g, "$&,");
          /*
        this.AssessmentTotalValue = data.AssessmentValue.replace(
          /.(?=(?:.{3})+$)/g,
          "$&,"
        );
        */
          this.Prop_AssessmentTotalValue = data.CurrAssessTotal.replace(
            /.(?=(?:.{3})+$)/g,
            "$&,"
          );
          this.Prop_AssessmentYear = data.AssessmentYear;
          this.Prop_AssessmentLand = data.CurrAssessLand.replace(
            /.(?=(?:.{3})+$)/g,
            "$&,"
          );
          this.Prop_AssessmentBuilding = data.CurrAssessBuilding.replace(
            /.(?=(?:.{3})+$)/g,
            "$&,"
          );
          this.Prop_LegalOwner = data.LO;
          this.hasPropData = true;
          this.AddressURL =
            "https://maps.googleapis.com/maps/api/streetview?size=360x300&fov=120&pitch=10&radius=100&source=outdoor&location=" +
            data.Address +
            "&key=AIzaSyDEtklVqe60jt7Z6NpX6DrBbu-f2UKePGs";
        } else this.hasPropData = false;
      });
  },

  components: {
    //BasicTableApp: BasicTableApp,
    taggedPosts: taggedPosts,
    PDFJSViewer,

    //V2Cupertino,
    //propertyinfo,
  },
  methods: {
    async getPortfolioRows(db_contactid) {
      var acontactid = db_contactid.split("-")[1];
      var adb = db_contactid.split("-")[0];
      // acontactid=5313
      // adb='apt'

      // var dbURL =
      //   "https://us-central1-terratonepull-c669d.cloudfunctions.net/PortfolioTreeView?database=" +
      //   adb +
      //   "&contactID=" +
      //   acontactid;
      var dbURL =
        "https://us-central1-manafund2022.cloudfunctions.net/PortfolioTreeView?database=mannafund&contactID=" +
        acontactid;

      let that = this;
      await axios.get(proxyurl1 + dbURL).then(function(response) {
        console.log("portfolio:", response.data[0]);
        EventBus.$emit("portfolio", response.data[0]);
      });
    },
    goToMap() {
      /*
      this.$ons.notification
        .toast("Updating the owner info screen", { timeout: 300 })
        .then((response) => {
          var myTabbar = document.querySelector("#maintabbar");
          console.log("myTabbar:", myTabbar);
          EventBus.$emit("go2map", this.coordinate);
        });
      var myTabbar = document.querySelector("#maintabbar");
      console.log("myTabbar:", myTabbar);
      */
      EventBus.$emit("go2map", this.coordinate);
    },

    goToOwnerEdit() {
      router.push({
        path: "/editowner",
        name: "editowner",
        params: { owner: this.owner, coordinate: this.coordinate },
      });
    },
    goToSearch() {
      router.push({
        path: "/search",
        name: "search",
        params: { strSearchFromOwnerInfo: this.Owner_Company },
      });
    },
    goToSearch2FN1() {
      this.actionSheetVisible = false;
      EventBus.$emit("SearchFrmOInfo", this.Owner_FullName1);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
    goToSearch2FN2() {
      this.actionSheetVisible = false;
      EventBus.$emit("SearchFrmOInfo", this.Owner_FullName2);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
    goToSearch2CY() {
      this.actionSheetVisible = false;
      EventBus.$emit("SearchFrmOInfo", this.Owner_Company);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
    showCompanySearch() {
      const pdf_name_prefix = "corporatesearch/";
      var pdf_name = pdf_name_prefix + this.Prop_LegalOwner + ".pdf";
      var starsRef = fb.storage.ref().child(pdf_name);
      var that = this;
      // Get the download URL
      starsRef
        .getDownloadURL()
        .then(function(url) {
          console.log("pdf URL: " + url);

          //EventBus.$emit("get_url", url);
          //var pdfurl = './TITLE-CA6771686-PID-004-101-235.pdf';
          that.path = "lib/web/viewer.html";
          that.showDialog = true;
          that.name = encodeURIComponent(url);
        })
        .catch((error) => {
          console.error("Company Search error:", error);
          this.$ons.notification.toast("No company search document found!", {
            timeout: 2000,
          });
        });
    },
    showTitleSearch() {
      const pdf_name_prefix = "titlesearch/";
      var pdf_name = pdf_name_prefix + this.Prop_PID + ".pdf";
      var starsRef = fb.storage.ref().child(pdf_name);
      var that = this;
      // Get the download URL
      starsRef
        .getDownloadURL()
        .then(function(url) {
          console.log("pdf URL: " + url);

          //EventBus.$emit("get_url", url);
          //var pdfurl = './TITLE-CA6771686-PID-004-101-235.pdf';
          that.path = "lib/web/viewer.html";
          that.showDialog = true;
          that.name = encodeURIComponent(url);
        })
        .catch((error) => {
          console.error("Title Search error:", error);
          this.$ons.notification.toast("No title search document found!", {
            timeout: 2000,
          });
        });
    },
    go2propPosts() {
      let senddata = { pid: this.Prop_PID, address: this.Prop_Address };
      EventBus.$emit("propPosts", senddata);
      document.querySelector("ons-tabbar").setActiveTab(4);
    },
    showStreetviewPage() {
      state.lonlat = this.lonlat;
      console.log("lonlat in propertyinfo", this.lonlat);
      router.push("streetview");
    },
    goToSearch2TL() {
      this.actionSheetVisible = false;
      console.log("Pid", this.Prop_PID);
      EventBus.$emit("SearchFrmPInfo", this.Prop_PID);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
    goToSearch2CY() {
      this.actionSheetVisible = false;
      console.log("LO", this.Prop_LegalOwner);
      EventBus.$emit("SearchFrmPInfo", this.Prop_LegalOwner);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
  },
};
</script>

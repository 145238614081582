<template id="app">
<v-ons-page id="main" >
    <v-ons-tabbar ref="maintab" id="maintabbar" position="top"  :index.sync="tabbarIndex" :tabs="tabs" @postchange="resetnewpostcount()" ></v-ons-tabbar>
  </v-ons-page>
</template>
<script>
import olmap2 from "../components/olmap2.vue";
import bca from "../components/bca.vue";
//import ownerinfo from "../components/ownerinfo";
//import propertyinfo from "../components/propertyinfo.vue";
//import search from "../components/Search.vue";
import Router from 'vue-router';
import posts2 from "../components/posts2.vue";
// Import the EventBus we just created.
import EventBus from '../config/event-bus';
import ownerpropinfo from "../components/ownerpropinfo.vue"



export default {
  name: "app",
  Router,
  data: function() {
    return {
      tabbarIndex:0,
      newpostcount:'',
      tabs: [
        { label: "Map", page: olmap2, icon: "md-map" },
       
        { label: "Details", page: ownerpropinfo, icon: "md-library"},
        //{ label: "Property", page: propertyinfo, icon: "md-city" },
       
        { label: "BCA", page: bca, icon: "md-book" } ,
        { id: "postTab", label: "Posts", page: posts2,icon: "fa-comment-alt"}  ,

        //{ page: search, icon: "md-search"  }  


      ],
      gPID:null 
    };
  },
  mounted(){
    EventBus.$on("newpostcount" ,(data)=>{
          //console.log("newpostcount:",data)
          
         // console.log("activ tab",document.getElementById("maintabbar").getActiveTabIndex())
          if (document.getElementById("maintabbar").getActiveTabIndex() != 4)
          {
            if (data==0){data=''} // prevent update the badge with 0
            this.newpostcount=data
            this.updatenewpostcount()
          }
          else 
          {
            this.newpostcount=''
            this.resetnewpostcount()
          }
                  

      })
  },
  methods:{
    resetnewpostcount(){
      if (document.getElementById("maintabbar").getActiveTabIndex() == 4) {
      var postTab=document.getElementById("postTab")
       postTab.setAttribute("badge",'')
        this.$store.commit("setnewpostcount",0)
      }
    },
    updatenewpostcount(){
            var postTab=document.getElementById("postTab")
       postTab.setAttribute("badge",this.newpostcount)
    }
  }
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
html,
body {
  
  margin:0;
}



</style>
import Vue from 'vue'
import Vuex from 'vuex'
import fb from "../firebase"
// Import the EventBus we just created.
import EventBus from '../config/event-bus';

import _ from 'lodash';

Vue.use(Vuex)
// handle page reload

var StartTime
StartTime=new Date().getTime()
console.log("StartTime",StartTime)
var usersListener
var likesListener
var postsListener
var commentsListener
fb.auth.onAuthStateChanged(user => {
  console.log("login/logout")

  if (user) {
     // alert("loadding user profile")
      
      //const postsCollection = db.collection('teams').doc('j93WMut5MeLsAz6HciJw/post/P3a4iL7OmIYeRDAjirrx').collection('posts')
      console.log("user:",user)
      if (! user.photoURL) {
          let photoURL='https://ui-avatars.com/api/?rounded=true&name=' + user.displayName
          user.updateProfile({
              photoURL:photoURL
          }).then (function() {
              console.log("photoURL assigned",photoURL)
              fb.usersCollection.doc(user.uid).update({ photoURL: photoURL })
          }).catch(function(error){
              console.log("photoURL error",error)
          })
      } 
      else 
      {
          console.log("photoURL from user object",user.photoURL)
      }
      store.commit('setCurrentUser', user)
      store.dispatch('fetchUserProfile')
      let team=null
      fb.usersCollection.doc(user.uid).get()
      .then((doc)=>{
          let team=doc.data().team
          console.log("team",team)
      })
      .catch((error)=>{
          console.log("Error getting document:",error)
      })
     
      usersListener= fb.usersCollection.doc(`${user.uid}/teams/L95NmDIgvQ6X337FiAKL/`).collection("bookmarks").where("deleted","==",false).orderBy('createdOn','desc').onSnapshot(querySnapshot=>{
        var bookmarkData = [];
        querySnapshot.forEach(function (doc) {
            bookmarkData.push({
            id: doc.id,
            data: doc.data()
            });
        });
        //console.log("bookmarkData",bookmarkData)

        store.commit("setBookmarks",bookmarkData)






    })

      likesListener= fb.likesCollection.where("userId","==",user.uid).onSnapshot(querySnapshot=>{
        var likedPostsData = [];
        querySnapshot.forEach(function (doc) {
            likedPostsData.push(doc.data().postId);
        });
        //console.log("bookmarkData",bookmarkData)

        store.commit("setLikedPosts",likedPostsData)
        console.log("likedPosts",likedPostsData)

    })

      commentsListener=fb.commentlikesCollection.where("userId","==",user.uid).onSnapshot(querySnapshot=>{
        var likedCommentsData = [];
        querySnapshot.forEach(function (doc) {
            likedCommentsData.push(doc.data().commentId);
        });
        //console.log("bookmarkData",bookmarkData)

        store.commit("setLikedComments",likedCommentsData)
        console.log("likedComments",likedCommentsData)

    })

/*
    fb.likesCollection.where("userId","==",user.uid).get()
       .then ((querySnapshot) =>{
        var likedPostsData = [];
        querySnapshot.forEach(function (doc) {
            likedPostsData.push(doc.data().postId);
        });
        //console.log("bookmarkData",bookmarkData)

        store.commit("setLikedPosts",likedPostsData)
        console.log("likedPosts",likedPostsData)

    })
*/


    fb.firebase2.auth().signInAnonymously()
    .then(()=>{
        console.log("Sign in Anonymously to storage project ok")
    })
    .catch((error)=>{
        console.log("Error sign in anonymously",error.message)
    })
    let postsArray = []
    fb.postsCollection.where("deleted","==",false).orderBy('createdOn', 'desc').get().then((querySnapshot)=>{
    
    querySnapshot.forEach(doc => {
        
        let post = doc.data()
        post.id = doc.id
        postsArray.push(post)
    })
})
    store.commit('setPosts', postsArray)
    
    store.commit("setnewpostcount",0)
    let inewpostcount=0
// realtime updates from our posts collection
  //  postsListener=fb.postsCollection.where("deleted","==",false).orderBy('createdOn', 'desc').onSnapshot(querySnapshot => {
    postsListener=fb.postsCollection.where("deleted","==",false).orderBy('createdOn','desc').onSnapshot(_.after(2, function(querySnapshot){


    
    

    querySnapshot.docChanges().forEach (change =>{
        if (change.type==='added'){
            //if (!change.doc.metadata.hasPendingWrites) {
                console.log("state:",store.getters.newpostcount)
                inewpostcount=store.getters.newpostcount+1
                console.log("Hi new msg added")
            //}
        }
    })
    console.log("new post count",inewpostcount)
        store.commit("setnewpostcount",inewpostcount)
        
        EventBus.$emit('newpostcount',inewpostcount)

    console.log(querySnapshot.size);
    console.log(querySnapshot.docChanges().length)
        let postsArray = []

        querySnapshot.forEach(doc => {
            let post = doc.data()
            post.id = doc.id
            postsArray.push(post)
        })

        store.commit('setPosts', postsArray)
    
    }
        
    )

    )
//}) //postsListener


}
else {
    if (usersListener) {usersListener()}
    if (likesListener) {likesListener()}
    if (commentsListener) {commentsListener()}
    if (postsListener) {postsListener()}
    
    
    
    
}
})
export const store= new Vuex.Store({
  state: {
    currentUser: null,
    userProfile: {},
    posts: [],
    hiddenPosts: [],
    bookmarks:[],
    newpostcount:0,
    initialpostcount:true,
    likedPosts:[],
    likedComments:[]

  },
  getters:{
    newpostcount: state=>
       state.newpostcount,
    
  },
  mutations: {
  setinitialpostcount(state,val){
    state.initialpostcount=val
  },
  setCurrentUser(state, val) {
      state.currentUser = val
  },
  setUserProfile(state, val) {
      state.userProfile = val
  },
  setBookmarks(state,val){
    if (val) {
        state.bookmarks = val
    } else {
        state.bookmarks = []
    }
  },
  setLikedPosts(state,val){
    if (val) {
        state.likedPosts = val
    } else {
        state.likedPosts = []
    }
  },
  setLikedComments(state,val){
    if (val) {
        state.likedComments = val
    } else {
        state.likedComments = []
    }
  },
  setnewpostcount(state,val){
    if (val) {
        console.log("set newpostcount",val)
        state.newpostcount = val
    } else {
        state.newpostcount = 0
    }
  },
  setPosts(state, val) {
      if (val) {
          state.posts = val
      } else {
          state.posts = []
      }
  },
  setHiddenPosts(state, val) {
      if (val) {
          // make sure not to add duplicates
          if (!state.hiddenPosts.some(x => x.id === val.id)) {
              state.hiddenPosts.unshift(val)
          }
      } else {
          state.hiddenPosts = []
      }
  }

  },
  actions: {

  clearData({ commit }) {
      commit('setCurrentUser', null)
      commit('setUserProfile', {})
      commit('setPosts', null)
      commit('setHiddenPosts', null)
      commit('setBookmarks',null),
      commit('setnewpostcount',null),
      commit('setLikedPosts',null)

  },
  fetchUserProfile({ commit, state }) {
      fb.usersCollection.doc(state.currentUser.uid).get().then(res => {
          commit('setUserProfile', res.data())
      }).catch(err => {
          console.log(err)
      })
  },
  updateProfile({ commit, state }, data) {
      console.log("data updateProfile",data)
      let newphotoURL=data.photoURL
      console.log("newphotoURL",newphotoURL)
      state.currentUser.updateProfile({
          photoURL: newphotoURL
      }).then (res=> {
        const userId =state.currentUser.uid
        // update user object
        fb.usersCollection.doc(userId).update({
          //username: user.displayName,
          //email: user.email,
          photoURL:newphotoURL
        }).then(user=>{
            store.dispatch('fetchUserProfile')
            // update all posts by user to reflect new name
            fb.postsCollection.where('userId', '==', state.currentUser.uid).get().then(docs => {
                docs.forEach(doc => {
                    fb.postsCollection.doc(doc.id).update({
                        userPhotoURL:newphotoURL
                    })
                })
            })
            // update all comments by user to reflect new name
            fb.commentsCollection.where('userId', '==', state.currentUser.uid).get().then(docs => {
                docs.forEach(doc => {
                    fb.commentsCollection.doc(doc.id).update({
                        userPhotoURL:newphotoURL
                    })
                })
            })
        })
      })

      
      /*
      let name = data.name

      fb.usersCollection.doc(state.currentUser.uid).update({ name }).then(user => {
          // update all posts by user to reflect new name
          fb.postsCollection.where('userId', '==', state.currentUser.uid).get().then(docs => {
              docs.forEach(doc => {
                  fb.postsCollection.doc(doc.id).update({
                      userName: name
                  })
              })
          })
          // update all comments by user to reflect new name
          fb.commentsCollection.where('userId', '==', state.currentUser.uid).get().then(docs => {
              docs.forEach(doc => {
                  fb.commentsCollection.doc(doc.id).update({
                      userName: name
                  })
              })
          })
      }).catch(err => {
          console.log(err)
      })
      */
  } ,
  async updateProfile2({ dispatch }, user) {
    const userId = fb.auth.currentUser.uid
    // update user object
    const userRef = await fb.usersCollection.doc(userId).update({
      username: user.displayName,
      email: user.email,
      photoURL:user.photoURL
    })

    dispatch('fetchUserProfile', { uid: userId })

    // update all posts by user
    const postDocs = await fb.postsCollection.where('userId', '==', userId).get()
    postDocs.forEach(doc => {
      fb.postsCollection.doc(doc.id).update({
        userName: user.name
      })
    })

    // update all comments by user
    const commentDocs = await fb.commentsCollection.where('userId', '==', userId).get()
    commentDocs.forEach(doc => {
      fb.commentsCollection.doc(doc.id).update({
        userName: user.name
      })
    })
  }

  },
  modules: {

  }
})

<template id="taggedposts">
  <v-ons-page id="taggedpostsPage">
    <div>
      <div v-if="filteredPosts.length">
        <!-- filteredPosts.length -->

        <div class="content" style="height: 85%">
          <div v-for="post in filteredPosts" class="post" :key="post.id">
            <v-ons-card :id="post.id">
              <v-ons-list-item class="postlistitem">
                <div class="left">
                  <img
                    class="list-item__thumbnail"
                    :src="
                      post.userPhotoURL
                        ? post.userPhotoURL
                        : 'https://ui-avatars.com/api/?rounded=true&name=' +
                          post.userName
                    "
                  />
                </div>
                <div class="center">
                  <span
                    class="list-item__title"
                    style="color: blue"
                    @click="oneClick(post.id)"
                    >{{ post.userName }}</span
                  >
                  <span class="list-item__subtitle" style="font-weight: bold"
                    >{{ post.title }} &#183; {{ post.createdOn | formatDate
                    }}<br />
                  </span>
                  <span
                    class="list-item__subtitle"
                    style="font-weight:bold;font-style:italic"
                  >
                    {{ post.selectedAddress }}</span
                  >
                </div>
                <div class="right">
                  <v-ons-icon
                    v-show="currentUser.uid == post.userId"
                    style="color: red;"
                    icon="md-delete"
                    class="list-item__icon"
                    @click="deletePost(post.id)"
                  ></v-ons-icon>
                </div>
              </v-ons-list-item>
              <div
                class="content"
                v-bind:data-raw="post.content"
                @click="expandPost"
              >
                {{ post.content | trimLength }}
              </div>
              <div style="margin-bottom:5px"></div>
              <div>
                <button
                  class="toolbar-button"
                  id="btnLikes2"
                  @click="toggleLikeUnLike(post.id, post.likes)"
                >
                  <i
                    :class="[
                      likedPosts.indexOf(post.id) != -1 ? 'fas' : 'far',
                      'fa-heart',
                    ]"
                    style="color:orange;font-size:20px; padding-left:0px;"
                  ></i>
                </button>
                <button class="toolbar-button" id="btnComment2"
                @click="viewpost(post)"
                >
                  
                  <i class="far fa-comment-alt" style="font-size:20px"></i>
                  {{ post.comments }}
                </button>
                <button
                  class="toolbar-button"
                  id="btnCopy"
                  @click="copy(post.content)"
                >
                  <i
                    class="fa fa-copy"
                    style="font-size:20px; padding-left:0px;"
                  ></i>
                </button>
                <button
                  class="toolbar-button"
                  style="float: right"
                  id="btnLikes2"
                  @click="getLikedUserList(post.id)"
                >
                  <i
                    class="fas fa-heart"
                    style="font-size:20px; padding-left:0px;"
                  ></i>
                  {{ post.likes }}
                </button>
                <button
                  v-show="post.centre"
                  style="float: right"
                  class="toolbar-button"
                  id="btnMap"
                  @click="go2Map(post)"
                >
                  <i
                    class="fas fa-map-marked"
                    style="font-size:20px; padding-left:0px;"
                  ></i>
                </button>
              </div>
            </v-ons-card>
          </div>
        </div>
      </div>
      <div v-else>
        <v-ons-card>
          <div class="content">
            <p
              style="text-align: center; font-size: 20px; margin-top: 20px; "
              class="no-results"
            >
              No posts yet.
            </p>
          </div>
        </v-ons-card>
      </div>
    </div>
       <v-ons-dialog cancelable :visible.sync="dialogVisible">
      <p>
        <v-ons-card>
          <span style="font-weight: bold;">Persons like the post</span>
          <v-ons-list v-for="(item, index) in likeduserlist" :key="index">
            <v-ons-list-item class="postlistitem">
              <div class="left">
                <img
                  class="list-item__thumbnail"
                  :src="
                    item.photoURL
                      ? item.photoURL
                      : 'https://ui-avatars.com/api/?rounded=true&name=' +
                        item.username
                  "
                />
              </div>
              <div class="center">
                <span class="list-item__title">{{ item.username }}</span>
              </div>
            </v-ons-list-item>
          </v-ons-list>
        </v-ons-card>
      </p>
    </v-ons-dialog>
  </v-ons-page>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import fb from "../firebase";
import EventBus from "../config/event-bus";

export default {
  mounted() {},
  props: ["hash"],
  data() {
    return {
      postdialogVisible: false,
      commentsdialogVisible: false,
      post: {
        content: "",
      },
      comment: {
        postId: "",
        userId: "",
        content: "",
        postComments: 0,
      },
      showCommentModal: false,
      showPostModal: false,
      fullPost: {},
      postComments: [],
      strPostSearch: "",
      filterPosts: [],
      selectedPostObj: null,
      delay: 700,
      clicks: 0,
      timer: null,
      propfilter: "",
      propfilteraddress: "",
      userfilter: "",
      userfilerButtonCaption: "All Posts",
      switchOn: false,
      actionSheetVisible: false,
      likeduserlist: [],
      dialogVisible: false,
      commentlikeduserlist: [],
      dialogCommentVisible: false,
    };
  },
  computed: {
    filteredPosts: function() {
      if (this.hash!=null){
      console.log("this.hash:", this.hash);
      return this.posts.filter((post) => post.hash === this.hash);
      } else return false
    },

    ...mapState([
      "userProfile",
      "currentUser",
      "posts",
      "hiddenPosts",
      "likedPosts",
      "likedComments",
    ]),
  },
  created() {
    if (navigator.clipboard) {
      this.supportsCB = true;
    }
    if (this.currentUser) {
      this.userfilter = "*";
      //this.userfilter = this.currentUser.uid;
    }
  },
  methods: {
    viewpost(apost) {
      EventBus.$emit('viewpost',apost);
      
    },
    expandPost(e) {
      e.target.innerHTML = e.target.dataset.raw;
    },
    getCommentLikedUserList(commentid) {
      fb.commentlikesCollection
        .where("commentId", "==", commentid)
        .get()
        .then((querySnapshot) => {
          let usercommentlikedlistData = [];
          let aUserName;
          querySnapshot.forEach(function(doc) {
            fb.usersCollection
              .doc(doc.data().userId)
              .get()
              .then((auser) => {
                aUserName = auser.data().username;
                usercommentlikedlistData.push(auser.data());
              });
            //userlikedlistData.push(doc.data().userId);
          });
          this.commentlikeduserlist = usercommentlikedlistData;
          console.log(" userlikedlistData", this.commentlikeduserlist);
          this.dialogCommentVisible = true;
        });
    },
    getLikedUserList(postid) {
      fb.likesCollection
        .where("postId", "==", postid)
        .get()
        .then((querySnapshot) => {
          let userlikedlistData = [];
          let aUserName;
          querySnapshot.forEach(function(doc) {
            fb.usersCollection
              .doc(doc.data().userId)
              .get()
              .then((auser) => {
                aUserName = auser.data().username;
                userlikedlistData.push(auser.data());
              });
            //userlikedlistData.push(doc.data().userId);
          });
          this.likeduserlist = userlikedlistData;
          console.log(" userlikedlistData", this.likeduserlist);
          this.dialogVisible = true;
        });
    },
    go2Map(apost) {
      console.log("apost", apost);
      EventBus.$emit("go2map", apost.centre);
      document.getElementById("maintabbar").setActiveTab(0);
    },
    toggleUserFilter() {
      if (this.userfilter == "*") {
        this.userfilter = this.currentUser.uid;
        this.userfilerButtonCaption = "Your posts only";
      } else {
        this.userfilter = "*";
        this.userfilerButtonCaption = "All posts";
      }
    },
    setpost2delete(postid) {
      fb.postsCollection
        .doc(postid)
        .update({
          deleted: true,
        })
        .then((ref) => {
          console.log("Post deleted successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    copy(postContent) {
      navigator.clipboard
        .writeText(postContent)
        .then(() => {
          this.$ons.notification.alert("Post content is copied.");
          console.log("Text is on the clipboard.");
        })
        .catch((e) => {
          console.error(e);
        });
    },
    deletePost(postid) {
      let that = this;
      console.log("deleting post:", postid);
      console.log("posts", that.posts);
      let aPost = that.posts.find((post) => post.id === postid);
      console.log("aPost", aPost);
      console.log("currentUserID", this.currentUser.uid);
      if (aPost.userId === this.currentUser.uid) {
        this.$ons.notification.confirm({
          title: "DELETE",
          messageHTML: "Do you really want to delete the post ?",
          buttonLabels: ["No", "Yes"],
          animation: "default",
          cancelable: true,
          callback: function(index) {
            if (index == -1) {
              console.log("confirm default");
              // that.selectedPostObj.style.backgroundColor="white"
            } else if (index == 0) {
              console.log("confirm:No");
              // that.selectedPostObj.style.backgroundColor="white"
            } else if (index == 1) {
              console.log("confirm:Yes");
              that.setpost2delete(postid);
            }
          },
        });
      } else {
        // that.selectedPostObj.style.backgroundColor="white"
        this.$ons.notification.alert("you can only delete your own post(s)!");
      }
    },
    toggleLikeUnLike(postId, postLikes) {
      if (this.likedPosts.indexOf(postId) != -1) {
        this.unlikePost(postId, postLikes);
      } else {
        this.likePost(postId, postLikes);
      }
    },
    unlikePost(postId, postLikes) {
      let docId = `${this.currentUser.uid}_${postId}`;

      fb.likesCollection
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            fb.likesCollection
              .doc(docId)
              .delete()
              .then(() => {
                fb.postsCollection.doc(postId).update({
                  likes: postLikes - 1,
                });
                console.log("unlike post successfully");
                return;
              })
              .catch((error) => {
                console.log("Error unlike post", error);
                return;
              });
            return;
          }
          console.log("currentUser", this.currentUser);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    likePost(postId, postLikes) {
      let docId = `${this.currentUser.uid}_${postId}`;

      fb.likesCollection
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            return;
          }
          console.log("currentUser", this.currentUser);
          fb.likesCollection
            .doc(docId)
            .set({
              postId: postId,

              userId: this.currentUser.uid,
            })
            .then(() => {
              // update post likes
              fb.postsCollection.doc(postId).update({
                likes: postLikes + 1,
              });
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val.length < 200) {
        return val;
      }
      return `${val.substring(0, 200)}...`;
    },
  },
};
</script>
<style scoped>
.postlistitem {
  padding-left: 0px;
}

.timestamp {
  font-size: 10px;
  font-style: italic;
  color: green;
  float: right;
}
</style>
